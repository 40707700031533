.no-border {
  & > div {
    border: none;
  }
}

.writeup {
  height: 200px;
  border: 1px dashed gray;
  cursor: pointer;
  transition: color 0.3s ease-in;
  &:hover {
    color: black;
    transition: color 0.3s ease-out;
  }
}

.writeup-item {
  height: 70px;
  background-color: rgba(120, 170, 246, 0.74);
  padding: 15px 20px;
  color: #487ee3;
  text-decoration: none;
  & > span {
    color: #333;
  }
}

.select-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.select-time input[type=text], input[type=time] {
  width: 29%;
}
.previewImages {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.previewImages img {
  min-width: 75px;
    height: 75px;
    border-radius: 3px !important;
    width: 50px;

}

.previewImages  button {
  position: absolute;
    right: -3px;
    color: #fff;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -5px;
    line-height: 0px;
    font-size: 10px;
}

.previewImages div {
  position: relative;
  width: 80px;
  margin-right: 5px;
  display: flex;
  padding: 0px !important;
}


.sidebar-brand.d-none.d-md-flex {
  background-color: #eeefef;
}

.loading-box {
  position: fixed;
  background: #dddddd8c;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 100000;
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-align {
  margin-top: 29px !important;
}
.color-box {
  margin-top: 32px;
  height: 38px;
  width: revert;
  padding: 0.175rem 0.2rem !important;
}
.textarea-color-box {
  margin-top: 52px;
  width: revert;
  height: 38px;
  padding: 0.175rem 0.2rem !important;
}
